<template>
  <div class="student-year">
    <div class="student-year__desc">
      <p>
        Уважаемый участник, не забудь загрузить в свое портфолио
        <a
          :href="`../documents/${
            type === 'collective' ? 'resume_collective_dg' : 'resume_dg'
          }.docx`"
          target="_blank"
        >
          анкету.
        </a>
        <!--        и-->

        &nbsp;Без неё ты не сможешь принять участие в конкурсе.
      </p>
      <p>
        Данный документ также можно найти в блоке
        <router-link
          :to="{ name: 'EventPage', params: { id: 3 } }"
          target="_blank"
        >
          «Нормативные документы».
        </router-link>
      </p>
      <p class="student-year__info">
        Все документы необходимо загружать с персонального компьютера или
        ноутбука во избежание технических неполадок
      </p>
    </div>
    <PDFInputFile
      label="Портфолио"
      name="Выбрать файл"
      :sizeFile="portfolioSize"
      :file-loaded="hasPortfolio?.url_original || Boolean(portfolioDoc)"
      :error="v$.formData.portfolio?.$errors?.length !== 0"
      :link-portfolio="hasPortfolio"
      @file-change="
        (value) => {
          saveDocument(value);
        }
      "
      type="portfolio"
      itemType="portfolio"
      :hasFile="isDisabled || isBtnDisabled"
    />
    <div class="student-year__agreement-item" v-if="!isDisabled">
      <Checkbox label="Согласие на обработку" v-model="privacy" />
      <a target="_blank" href="https://ligastudentov.com/privacy">
        персональных данных
      </a>
    </div>
    <div class="student-year__btn">
      <Button
        @click="sendStatement"
        :loading="isBtnLoading || isSending"
        :disabled="!hasPortfolio && !Boolean(portfolioDoc)"
        v-if="!isDisabled"
      >
        Подать заявку
      </Button>
      <Button
        @click="openPopup"
        secondary
        :loading="isDeleteBtnLoading"
        v-if="rollbackAllowed"
      >
        Отказ от участия
      </Button>
      <Button secondary v-if="false">Сохранить как черновик</Button>
    </div>

    <div class="student-year__comments" v-if="commentsList?.length > 0">
      <div
        class="student-year__comments-title"
        v-if="currentCategoryData?.expert_comments?.data?.length > 0"
      >
        Комментарии от экспертов
      </div>
      <div class="student-year__comments-list">
        <div
          class="student-year__comments-item"
          v-for="(item, index) in commentsList"
          :key="index"
        >
          <p>{{ item?.expert_info?.fio }}</p>
          <span>{{ item?.comment }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Blocks/Button";
import PDFInputFile from "@/components/Blocks/PDFInputFile";
import useVuelidate from "@vuelidate/core";
import validations from "@/enums/validations";
import trayValidationsTexts from "@/enums/trayValidationsTexts";
import moment from "moment";
import { mapGetters, mapState } from "vuex";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import Checkbox from "@/components/Blocks/Checkbox";

export default {
  name: "AchievementYearIndividualPortfolio",
  components: { Checkbox, PDFInputFile, Button },
  props: ["currentProject", "type"],
  setup() {
    return {
      v$: useVuelidate({ $lazy: true }),
    };
  },
  data() {
    return {
      isBtnLoading: false,
      isBtnDisabled: false,
      isSending: false,
      statementDocs: [],
      savedDoc: {},
      formData: {
        portfolio: "",
      },
      privacy: false,
    };
  },
  watch: {
    portfolioDoc: {
      handler: function () {
        Object.keys(this.formData).forEach((item) => {
          return (this.formData[item] = this.portfolioDoc);
        });
      },
      deep: true,
    },
  },
  validations() {
    if (this.hasPortfolio) {
      validations.studentYearPortfolioData.portfolio = {};
    }
    return { formData: validations.studentYearPortfolioData };
  },
  computed: {
    fieldsInfo() {
      return trayValidationsTexts.studentYearPortfolioData;
    },
    isDisabled() {
      let boolean;
      if (Object.values(this.currentCategoryData).length > 0) {
        if (this.validPeriodForRollback) {
          if (this.currentCategoryData?.status?.key === 0) {
            boolean = false;
          } else boolean = this.currentCategoryData?.status?.key !== 35;
        } else if (this.validPeriodFor35Status) {
          boolean = this.currentCategoryData?.status?.key !== 35;
        } else return true;
      }
      return this.isOldApplication || boolean;
    },
    openRegistrationStage() {
      return this.currentProject?.settings?.stages?.data.find((item) => {
        return item.stage_key === "open_registration";
      });
    },
    checkStatementEmployee() {
      return this.currentProject?.settings?.stages?.data.find((item) => {
        return item?.stage_key === "check_statement_employee";
      });
    },
    validPeriodForRollback() {
      return moment().isBetween(
        moment(this.openRegistrationStage?.start_at),
        moment(this.openRegistrationStage?.end_at),
        "day",
        "[]"
      );
    },
    validPeriodFor35Status() {
      return moment().isBetween(
        moment(this.checkStatementEmployee?.start_at),
        moment(this.checkStatementEmployee?.end_at),
        "day",
        "[]"
      );
    },
    statuses() {
      return JSON.parse(
        JSON.stringify(
          this.storage?.systemInfo?.list_statuses[
            `${this.currentCategoryData?.info_project?.key}`
          ] ?? []
        )
      );
    },
    currentStatus() {
      return this.statementId
        ? "0"
        : Object.keys(this.statuses).find((item) => {
            return +item === this.currentCategoryData?.status?.key;
          });
    },
    rollbackAllowed() {
      let boolean;
      if (this.validPeriodForRollback) {
        if (
          this.currentProject?.id === 1 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean =
            this.currentStatus === "0" ||
            this.currentStatus === "1" ||
            this.currentStatus === "50";
        }
        if (
          this.currentProject?.id === 2 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean =
            this.currentStatus === "0" ||
            this.currentStatus === "1" ||
            this.currentStatus === "50";
        }
        if (
          this.currentProject?.id === 3 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean =
            this.currentStatus === "0" ||
            this.currentStatus === "1" ||
            this.currentStatus === "50";
        }
        if (this.currentProject?.id === 4) {
          boolean =
            this.currentStatus === "0" ||
            this.currentStatus === "1" ||
            this.currentStatus === "50";
        }
      } else if (this.validPeriodFor35Status) {
        if (
          this.currentProject?.id === 2 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean = this.currentStatus === "50";
        }
        if (
          this.currentProject?.id === 3 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean = this.currentStatus === "50";
        }
      } else boolean = false;

      return this.isOldApplication ? false : boolean;
    },
    hasPortfolio() {
      let buffer = JSON.parse(JSON.stringify(this.statementsList));

      buffer = buffer.find((item) => {
        return this.statementId
          ? item.id === this.statementId
          : item.id === this.currentCategoryData?.id;
      });

      return buffer?.info_documents?.data
        ?.sort((a, b) => {
          return (
            moment(a.info_document?.created_at) -
            moment(b.info_document?.created_at)
          );
        })
        .slice(-1)[0]?.info_document;
    },
    commentsList() {
      return this.currentCategoryData?.expert_comments?.data.filter((item) => {
        return item.comment;
      });
    },
    ...mapState([
      "currentCategoryData",
      "portfolioDoc",
      "portfolioDocId",
      "statementId",
      "achievementYearCollectiveData",
      "statementsList",
      "isOldApplication",
      "isDeleteBtnLoading",
      "storage",
    ]),
    ...mapState("user", ["disabledFields"]),
    ...mapGetters(["portfolioSize"]),
  },
  methods: {
    openPopup() {
      this.$store.commit("setCurrentPopup", {
        name: "PopupRevokeApplication",
        isShown: true,
        props: {
          id: this.statementId
            ? this.statementId
            : this.currentCategoryData?.id,
          fromProject: true,
        },
      });
    },
    async saveDocument(value) {
      await this.$store.commit("setPortfolioDoc", value);
      this.formData.portfolio = await value;

      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$store.dispatch("validationErrors", {
          errors: this.v$.$errors,
          fieldsInfo: this.fieldsInfo,
        });
        return;
      }

      this.isBtnLoading = true;
      this.isBtnDisabled = true;

      await createRequest(requestConfigs.POSTSaveDocument, {
        jsonPayload: {
          file: this.formData.portfolio,
        },
      })
        .then((response) => {
          this.$store.commit("pushToTray", {
            text: "Документ сохранен",
            type: "success",
          });
          this.savedDoc = response.data;
          this.$store.commit("setPortfolioDocId", response.data.id);
        })
        .then(() => {
          let payloadObject = {};
          payloadObject.media_id = this.portfolioDocId;
          payloadObject.project_tree_id =
            this.$store.state[`AY${this.type}NominationValue`];

          if (this.type === "collective") {
            Object.keys(this.achievementYearCollectiveData).forEach((item) => {
              payloadObject[`other[${item}]`] =
                this.achievementYearCollectiveData[item];
            });
          }
          createRequest(requestConfigs.POSTAddDocument, {
            jsonPayload: payloadObject,
            routerPayload: {
              id: this.statementId || this.currentCategoryData.id,
            },
          })
            .then(() => {
              this.$store.commit("pushToTray", {
                text: "Документ прикреплен к заявке",
                type: "success",
              });
              if (this.statementDocs && this.statementDocs.length > 0) {
                this.statementDocs.forEach(async (item) => {
                  await createRequest(requestConfigs.DELStatementDocument, {
                    jsonPayload: { media_id: item.id },
                    routerPayload: {
                      id: this.statementId || this.currentCategoryData.id,
                    },
                  });
                  this.statementDocs = this.statementDocs.filter((i) => {
                    return i.id !== item.id;
                  });
                });
                this.statementDocs.push(this.savedDoc);
              }
            })
            .catch((error) => {
              this.$store.commit("pushToTray", {
                text: error.errors[0].error_descr,
                type: "error",
              });
            })
            .finally(() => {
              this.isBtnDisabled = false;
              this.isBtnLoading = false;
            });
        })
        .catch((error) => {
          this.$store.commit("pushToTray", {
            text: error.errors[0].error_descr,
            type: "error",
          });
        })
        .finally(() => {});
    },
    async sendStatement() {
      if (
        (this.hasPortfolio && !this.hasPortfolio) ||
        (this.portfolioDoc && !this.portfolioDoc)
      ) {
        this.$store.commit("pushToTray", {
          text: "Портфолио должно быть загружено",
          type: "error",
        });
        return;
      }
      if (
        !this.$store.state.user.disabledFields.isAYCollectiveDataFilled &&
        this.type === "collective"
      ) {
        this.$store.commit("pushToTray", {
          text: "Данные не заполнены или не сохранены",
          type: "error",
        });
        await this.$router.push({ name: "AchievementYearCollectiveData" });
        return;
      }
      if (!this.privacy) {
        this.$store.commit("pushToTray", {
          text: "Подача возможна при согласии на обработку перс. данных",
          type: "error",
        });
        return;
      }
      this.isSending = true;
      await createRequest(requestConfigs.POSTSendStatement, {
        routerPayload: { id: this.statementId || this.currentCategoryData.id },
      })
        .then(() => {
          this.$store.commit("pushToTray", {
            text: "Заявка успешно создана",
            type: "success",
          });
          this.$store.commit("setStatementId", "");
          this.$router.push({ name: "Applications" });
          this.$store.commit("setPortfolioDoc", "");
        })
        .catch((error) => {
          this.$store.commit("pushToTray", {
            text: error.errors[0].error_descr,
            type: "error",
          });
        })
        .finally(() => {
          this.isSending = false;
        });
    },
  },
  mounted() {
    if (this.portfolioDoc) {
      this.formData.portfolio = this.portfolioDoc;
    }
    if (this.statementsList) {
      let buffer = JSON.parse(JSON.stringify(this.statementsList));

      buffer = buffer.find((item) => {
        return this.statementId
          ? item.id === this.statementId
          : item.id === this.currentCategoryData?.id;
      });
      this.statementDocs = buffer?.info_documents?.data.map((item) => {
        item.id = item.info_document.id;
        return item;
      });
    }
  },
};
</script>

<style lang="scss">
.student-year {
  &__desc {
    margin-bottom: 15px;
    max-width: 728px;
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    a {
      color: #6366f1;
      text-decoration: underline;
      &:hover {
        -webkit-text-stroke-width: 0.05rem;
      }
    }
  }
  &__comments {
    margin-top: 40px;
    color: var(--text--color);
    max-width: 728px;
    &-title {
      font-weight: 500;
      font-size: 2.2rem;
      line-height: 2.7rem;
      margin-bottom: 20px;
    }
    &-list {
      display: flex;
      flex-direction: column;
      grid-gap: 15px;
    }
    &-item {
      padding-bottom: 15px;
      border-bottom: 1px solid #bfbfbf;
      p {
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 2rem;
        margin-bottom: 15px;
      }
      span {
        font-size: 1.4rem;
        line-height: 1.6rem;
      }
    }
  }
  &__agreement-item {
    display: flex;
    flex-wrap: wrap;
    @include link();
    gap: 5px;
    & > div {
      margin: 0;
    }
    a {
      font-size: 1.4rem;
    }
  }
}
</style>
